<template>
  <div class="course-page page">
    <p>一、活动时间：4月25日上午10：00-4月28日上午10：00</p>
    <p>二、活动内容：</p>
    <p>1.用户在活动期间购买指定产品，可享受一定折扣。</p>
    <p>2.开启年卡、两年卡后，你所获得的服务期限分别为372个自然日、744个自然日。</p>
    <p>3.你需要将有鱼记账帐号与前述账户进行绑定，且保证有足够余额支持代扣。因上述账户余额不足导致续费失败的，你将自行承担相应结果。</p>
    <p>三、付费服务</p>
    <p>1.你理解并同意，会员订阅服务为付费服务，有鱼记账将收取互联网增值服务使用费；你可以在相关服务页面查阅会员订阅服务具体期限及对应费用并自行选择服务的种类。
      开启会员订阅功能后，无论你是否在相应服务期限内实际使用该服务，已支付的费用不支持退款。</p>
    <p>2.你理解并同意，我司有权自行决定并不时修订会员订阅服务相关的收费标准和规则，该类标准及规则一经发布即生效，并构成本条款的有效组成部分。
      如你不同意前述标准、规则及其修订，你有权停止使用会员订阅服务。你继续使用会员订阅服务即视为你同意相关内容。</p>
    <p>四、免责声明</p>
    <p>我司有权根据产品计划和运营情况独立决定会员订阅服务的具体内容，并有权根据情况新增、移除暂停或终止提供会员订阅服务的部分服务。前述决定不经通知即生效，你同意我司对此免责。</p>
    <p>
      <span style="color: rgb(227, 55, 55);">
        <br>
      </span>
    </p>
    <p>
      <span style="color: rgb(227, 55, 55);">注：会员到账时间可能有延迟，若会员未到账可杀掉APP进程，重新进入</span>
    </p>
    <p>
        <img alt="Image" src="https://download-cos.yofish.com/youyu-gongjushiyebu/20221226164534990-kefu.png" width="200" height="200">
        <br>
    </p>
</div>
</template>
<script>
export default {
  name: 'Rule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
