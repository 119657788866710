<template>
  <div class="nyd-page page">
  <div class="loading" v-show="loading">{{ textcontent }}</div>
    <div class="nyd-main img-common">
      <div class="rule-btn" @click="ruleVisible = true">活动规则</div>
      <div class="head img-common"></div>
      <div class="card card1">
        <div class="card-label img-common">
          <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230417172448111-price-title.png" alt="" />
        </div>
        <div class="pay-list">
          <div class="card-box"
            v-for="(item, index) in cardList"
            :key="index"
            :class="[`box-${index+1}`, { checked: curIndex === index }]"
            @click="clickAction(index)">
            <div class="jb-sign" v-if="item.type === 2">
              <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20230418133726867-jb-sign.png" alt="" />
            </div>
            <div class="spare-sign" v-if="item.type === 2">
              <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/2023041813452129-sp-sign.png" alt="" />
            </div>
            <div class="card-img">
              <img
                v-show="curIndex !== index"
                :src="item.imgUrl" alt="" />
              <img
                v-show="curIndex === index"
                :src="item.checkedImgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="card card2">
        <div class="card-label img-common">
          <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230417173201128-vip-title.png" alt="" />
        </div>
        <div class="image-row">
          <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20230419103457116-vip-bg.png" alt="" />
        </div>
      </div>
      <div class="bottom-btn" @click="payAction">
        <div class="pay-btn">
          ¥{{ price }}立即秒杀
          <!-- <img
  src="//download-cos.yofish.com/yofish-gongjushiyebu/20230417163902224-ny-btn.png" alt="" /> -->
          <div class="time-tips" v-if="leftOverTime > 0">
            <div class="time-text">倒计时：{{ ctime }}</div>
          </div>
        <!-- <div class="bottom-row">
          <span class="price">¥{{ price }} </span>立即秒杀
           <div class="sign" v-if="leftOverTime > 0">倒计时：{{ ctime }}</div> -->
        </div>
      </div>
    </div>
    <y-dialog :visible.sync="visible" class="pay-success-dialog">
      <div class="close-btn" @click="visible = false">
        <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220615110726923-close-btn.png"
        alt="">
      </div>
      <div class="pay-success-title">
        <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230418143407987-pay-title.png" alt="" />
      </div>
      <div class="crown-sign">
        <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230418144101429-crown.png" alt="" />
      </div>
      <div class="pay-success-desc">
        <p>恭喜您成为有鱼会员！</p>
        <p>可前往会员中心页查看权益</p>
      </div>
      <div class="button" @click="jumpVipAction">
        立即前往
      </div>
    </y-dialog>
    <y-dialog :visible.sync="dialogData.visible" class="activity-dialog">
      <div class="activity-dialog-row">
        <div class="content">
          <p class="title">{{dialogData.title}}</p>
          <p class="desc">{{dialogData.content}}</p>
        </div>
        <div class="btn" @click="closeAction">我知道了</div>
      </div>
    </y-dialog>
    <div class="loading-animate">
      <y-loading v-show="loadingVisible" />
    </div>
    <y-actionsheet :is-visible="ruleVisible"
      :isLockBgScroll="true"
      @close="closeActionsheet">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
  </div>
</template>
<script>
import {
  getPrompotActivityApi, getOrderPayStatusApi,
  getUserOrderPayStatusApi,
} from '@/api/base1';
import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import YActionsheet from '@/components/actionsheet';
import { getAppStatus } from '@/utils/app';
import { jumpVipPgae, jsClosePage, jsBriageEvent } from '@/utils/bridge';
import rule from './rule';

export default {
  name: 'ActivityNyd',
  components: {
    YDialog,
    YLoading,
    YActionsheet,
    rule,
  },
  data() {
    return {
      cardList: [
        {
          imgUrl: '//download-cos.yofish.com/yofish-gongjushiyebu/20230419102724773-ncard1.png',
          checkedImgUrl: '//download-cos.yofish.com/yofish-gongjushiyebu/20230418112405401-ncard1-checked.png',
          price: 88,
          testPrice: 0.01,
          checked: false,
          type: 1, // 一年卡
        },
        {
          imgUrl: '//download-cos.yofish.com/yofish-gongjushiyebu/20230419102900748-ncard2.png',
          checkedImgUrl: '//download-cos.yofish.com/yofish-gongjushiyebu/20230418092517262-ncard2-checked.png',
          price: 108,
          testPrice: 0.02,
          checked: false,
          type: 2, // 两年卡
        },
      ],
      curIndex: 1,
      ctime: '--:--:--',
      leftOverTime: 0,
      visible: false,
      dialogData: {
        visible: false,
        title: '活动已结束',
        content: '当前活动未开始',
      },
      timer: null,
      loadingVisible: false,
      loading: false,
      textcontent: '',
      ruleVisible: false,
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return '7fafe1a4-ec10-4a41-b33e-365845d41032';
    },
    appVersion() {
      return getAppStatus().releaseVersion;
    },
    activityId() {
      return 3;
    },
    businessType() {
      return 1;
    },
    cardData() {
      const { curIndex } = this;
      let cardData = this.cardList.find((item) => item.type === curIndex + 1);
      return cardData;
    },
    price() {
      let { cardData } = this;
      let { origin } = window.location;
      const { price, testPrice } = cardData;
      let value = origin.includes('jz-h5.yofish.com') ? price : testPrice;
      return value;
    },
    goodsId() {
      const { cardData } = this;
      let value = '';
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        value = cardData.type === 1 ? '20221001_ios_one_year_vip' : '20221001_ios_two_year_vip';
      }
      if (/android/i.test(navigator.userAgent)) {
        value = cardData.type === 1 ? '20221001_android_one_year_vip' : '20221001_android_two_year_vip';
      }
      return value;
    },
  },
  watch: {
    ruleVisible(val) {
      const page = document.querySelector('.page');
      if (val) {
        page.style.overflow = 'hidden';
      } else {
        page.style.overflow = 'auto';
      }
    },
    visible(val) {
      if (!val) {
        let orderId = localStorage.getItem('orderId');
        if (orderId) localStorage.removeItem('orderId');
      }
    },
  },
  mounted() {
    document.title = '五一促销活动';
    this.iniData();
    this.blindMonitor();
    this.$nextTick(() => {
      this.pageScroll();
    });
  },
  methods: {
    clickAction(index) {
      this.curIndex = index;
    },
    async iniData() {
      let res;
      const { cuserId, activityId } = this;
      this.loadingVisible = true;
      const orderId = localStorage.getItem('orderId');
      try {
        res = await getPrompotActivityApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.loadingVisible = false;
        return this.$toast({ content: e });
      }
      this.loadingVisible = false;
      if (this.timer) window.clearInterval(this.timer);
      if (res?.code === 1) {
        // res.results.activityStatus = 1;
        let { activityStatus, leftOverTime } = res?.results || {};
        if (activityStatus === 0) {
          this.dialogData = {
            visible: true,
            title: '活动未开始',
            content: '当前活动未开始',
          };
        }
        if (activityStatus === 2) {
          this.dialogData = {
            visible: true,
            title: '活动已结束',
            content: '当前活动已结束',
          };
        }
        if (activityStatus === 1) {
          this.leftOverTime = leftOverTime;
          this.countTime(leftOverTime);
          this.timer = window.setInterval(this.countTime, 1000);
          let goodsId = sessionStorage.getItem('goodsId');
          goodsId && orderId && this.getOrderPayStatus(goodsId, orderId);
          console.log('goodsId', goodsId);
        }
      } else {
        return this.$toast({ content: res?.desc || '网络异常' });
      }
    },
    async getOrderPayStatus(goodsId, orderNo) {
      let res;
      const {
        activityId,
        cuserId,
      } = this;
      try {
        res = await getOrderPayStatusApi({
          activityId,
          orderNo,
          cuserId,
          comboId: goodsId,
        });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1 && res.results) {
        this.visible = true;
        this.cardExposureMonitor(goodsId);
      }
    },
    payAction() {
      let { price, cardData, goodsId } = this;
      let eventName = cardData.type === 1 ? 'gqfjhdy_ljmsan_1_click' : 'gqfjhdy_ljmsan_2_click';
      let name = '国庆大放“价”活动页-立即秒杀按钮点击';
      name += price === cardData.type === 1 ? '（1年卡）' : '（2年卡）';
      window._monitor('track', eventName, {
        eventName: name,
      });
      jsBriageEvent({ eventId: 'wyhdgman_click', events: { product_name: cardData.type === 1 ? '1年卡' : '2年卡' } });
      let { origin } = window.location;
      let payMoney = price;
      let params = {
        payMoney,
        payType: 0, // 0 支付宝 1：零钱
        smsCode: '', // 验证码 payType=1必传
        jumpUrl: origin + '/activity/nyd', // 跳转链接 payType=0必传
        scenes: 1, // 支付场景 0：皮肤 1：购买会员 2:导出数据 3：数据恢复 4：打赏 6: 拆盲盒
        goodsId, // 购买的商品id
        orderId: '', // 订单id ：scenes=2和3必传(需要先调用对应的生成订单接口)
        memo: '', // 备注 ：scenes=4必传
        activityId: this.activityId,
      };
      sessionStorage.setItem('goodsId', goodsId);
      this.$router.push({
        path: '/payment',
        query: params,
      });
    },
    blindMonitor() {
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'gqfjhdym_imp', {
        eventName: '两年卡活动页面曝光',
      });
      jsBriageEvent({ eventId: 'wyhdsy_imp' });
    },
    cardExposureMonitor(goodsId) {
      let goodsIdYear = ['20221001_ios_one_year_vip', '20221001_android_one_year_vip'];
      let eventName = goodsIdYear.includes(goodsId) ? 'gqfjhdy_zfcgtc_1_imp' : 'gqfjhdy_zfcgtc_2_imp';
      let name = '两年卡活动页-支付成功弹窗曝光';
      name += goodsIdYear.includes(goodsId) ? '（1年卡）' : '（2年卡）';
      window._monitor('pageset', eventName, {
        eventName: name,
      });
      jsBriageEvent({ eventId: 'wyhdzfcgtc_imp', events: { product_name: goodsIdYear.includes(goodsId) ? '1年卡' : '2年卡' } });
    },
    pageScroll() {
      let startPosition = 0;
      const page = document.querySelector('.page');
      let distance = 0;
      let scrollEl = document.documentElement || document.body;
      page.addEventListener('touchstart', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll || top > 0) return false;
        this.textcontent = '下拉刷新';
        startPosition = e.touches[0].pageY;
      });
      page.addEventListener('touchmove', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll) return false;
        let currentPosition = e.touches[0].pageY;
        distance = currentPosition - startPosition;
        if (distance <= 0 || top > 0) return false;
        if (distance > 80) {
          this.textcontent = '释放立即刷新';
          this.loading = true;
        }
        if (distance > 80 && distance < 150) {
          this.loading = true;
          this.iniData();
          this.style = {
            transition: 'transform .6s',
            transform: 'translateY(.5rem)',
          };
        }
      });
      page.addEventListener('touchend', (e) => {
        const top = 'scrollTop' in page ? page.scrollTop : 0;
        if (this.disabledScroll || top > 0) return false;
        if (distance > 0 && distance < 80) {
          this.style = {};
          this.loading = false;
          return false;
        }
        if (distance > 80) {
          this.style.transform = 'translateY(1rem)';
          this.textcontent = '正在刷新...';
          this.loading = true;
          setTimeout(() => {
            this.style = {};
            this.loading = false;
            distance = 0;
          }, 400);
        }
      });
    },
    countTime() {
      let time = this.leftOverTime || 0;
      if (time <= 0) {
        this.ctime = '--:--:--';
        window.clearInterval(this.timer);
        return false;
      }
      this.leftOverTime -= 1;
      let hours = Math.floor(time / 3600);
      hours = hours > 9 ? hours : '0' + hours;
      let mins = Math.floor((time / 60) % 60);
      mins = mins > 9 ? mins : '0' + mins;
      let s = Math.floor(time % 60);
      s = s > 9 ? s : '0' + s;
      this.ctime = hours + ':' + mins + ':' + s;
    },
    closeActionsheet() {
      this.ruleVisible = false;
    },
    jumpVipAction() {
      this.visible = false;
      let goodsId = sessionStorage.getItem('goodsId');
      let goodsIdYear = ['20221001_ios_one_year_vip', '20221001_android_one_year_vip'];
      let name = goodsIdYear.includes(goodsId) ? '（1年卡）' : '（2年卡）';
      jsBriageEvent({ eventId: 'wyhdzfcgtc_click', events: { product_name: name } });
      jumpVipPgae(1);
    },
    closeAction() {
      jsClosePage();
    },
  },
  beforeDestroy() {
    if (this.timer) window.clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/index.scss';
@import './index.scss';
</style>
